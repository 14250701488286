<template>
  <v-card>
    <v-card-title>
      <span>{{ $props.title }}</span>
      <v-spacer></v-spacer>
      <v-col cols="4" md="2">
        <v-select
          v-if="$props.select"
          :value="$props.select.value"
          :items="$props.select.items"
          @change="onChangeSelect"
          outlined
          hide-details
          dense
        ></v-select>
      </v-col>
    </v-card-title>
    <v-card-text>
      <vue-apex-charts
        ref="chartRef"
        :type="$props.type"
        height="300"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import themeConfig from '@themeConfig'
import { onUnmounted, ref } from 'vue'

export default {
  components: { VueApexCharts },
  props: {
    title: { type: String, default: '' },
    chartLabel: { type: String, default: '' },
    data: { type: Array, default: [] },
    xaxis: { type: Array, default: [] },
    select: { type: Object },
  },
  setup(props, { emit }) {
    const $themeColors = themeConfig.themes.light
    const chartRef = ref()
    const lineChartSimple = {
      series: [
        {
          data: props.data,
        },
      ],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.secondary],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'straight',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            } ${props.chartLabel}</span></div>`
          },
        },
        xaxis: {
          categories: props.xaxis,
        },
        yaxis: {
          // opposite: isRtl,
        },
      },
    }

    const onChangeSelect = value => {
      emit('onSelect', value)
      setTimeout(() => {
        chartRef.value.updateSeries(
          [
            {
              data: props.data,
            },
          ],
          false,
          true,
        )
      }, 500)
    }

    return {
      chartRef,
      onChangeSelect,
      lineChartSimple,
    }
  },
}
</script>
